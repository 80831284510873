import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { formatDate, generateDays, keycodeHandle, deepClone, deduplicate, isSameDay, getElementWidthWithMargin, getElementHeightWithMargin, setStorage, getStorage, removeStorage, scrollTextOnFocus, findChildNodeDeep } from '../../util/util'
import $request from '../../api';
import GLOBAL from '../../util/global';
import './style/LiveEventAll.scss';
const monthsShort = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]; // 月份简称
export default withTranslation()(class LiveEventAll extends Component {
    constructor(props) {
        super(props);
        this.liveEventWrapperRef = React.createRef();
        this.pageWheelTimer = null; // 鼠标滚轮监听延时器
        this.scrollHandler = null; // 滚动
        this.state = {
            dayList: generateDays(21, 11, true), // 格式化最近21天
            categoryId: this.props.match.params.categoryId, // 分类ID
            categoryName: this.props.match.params.name, // 分类名称
            liveEventList: [] // 事件列表
        }
    }

    componentWillMount() {
        this.getLiveEventCategoryList();
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        keycodeHandle({
            exit: () => {
                if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_LIVE_EVENT_FOCUS_INFO)) {
                    removeStorage(GLOBAL.STORAGE_KEYS.CATEGORY_LIVE_EVENT_FOCUS_INFO);
                }
                window.history.back(-1)
            },
            quit: () => {
                if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_LIVE_EVENT_FOCUS_INFO)) {
                    removeStorage(GLOBAL.STORAGE_KEYS.CATEGORY_LIVE_EVENT_FOCUS_INFO);
                }
                window.history.back(-1)
            },
        });
    }

    componentWillUnmount() {
        this.handleRemoveListenerEvent();
        clearTimeout(this.pageWheelTimer);
    }

    /**
     * 获取事件分类列表
     */
    getLiveEventCategoryList() {
        const { categoryId, dayList } = this.state;
        $request.boApi.liveEvent.getLiveEventCategoryList({
            categoryId,
            page: 0,
            pageSize: -1,
            startDate: dayList[0].formatDate,
            endDate: dayList[dayList.length - 1].formatDate
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.data && res.data.data.liveEvents) {
                    let liveEventList = deepClone(res.data.data.liveEvents);
                    // 按开始日期进行组件分组
                    liveEventList.forEach(item1 => {
                        item1.reservationStatus = 0;
                        item1.startDateStr = formatDate(item1.startTime, "yyyy-MM-dd");
                    })
                    liveEventList.forEach(item1 => {
                        liveEventList.forEach(item2 => {
                            if (item1.startDateStr == item2.startDateStr) {
                                if (!item1.events) {
                                    item1.events = [];
                                }
                                item1.events.push(item2);
                            }
                        })
                    })
                    liveEventList = deduplicate(liveEventList, "startDateStr");
                    this.setState({
                        liveEventList
                    }, () => {
                        if (liveEventList.length) {
                            if (getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_LIVE_EVENT_FOCUS_INFO)) {
                                let foucsInfo = getStorage(GLOBAL.STORAGE_KEYS.CATEGORY_LIVE_EVENT_FOCUS_INFO);
                                if (foucsInfo.y) {
                                    if (foucsInfo.scrollYEl && document.getElementById(foucsInfo.scrollYEl)) {
                                        document.getElementById(foucsInfo.scrollYEl).style.top = foucsInfo.y + 'px';
                                    }
                                }
                                if (foucsInfo.x) {
                                    if (foucsInfo.scorllXEl && document.getElementById(foucsInfo.scorllXEl)) {
                                        document.getElementById(foucsInfo.scorllXEl).style.left = foucsInfo.x + 'px';
                                    }
                                }
                                if (foucsInfo.focusId) {
                                    if (document.getElementById(foucsInfo.focusId)) {
                                        $tv.requestFocus(document.getElementById(foucsInfo.focusId));
                                    }
                                } else {
                                    $tv.requestFocus(document.getElementById(liveEventList[0].events[0].assetId));
                                    document.getElementById(foucsInfo.scrollYEl).style.top = 0;
                                    document.getElementById(foucsInfo.scorllXEl).style.left = 0;
                                }
                            } else {
                                if (liveEventList[0].events.length) {
                                    $tv.requestFocus(document.getElementById(liveEventList[0].events[0].assetId));
                                }
                            }
                        }
                        this.handleListenerEvent();
                    })
                }
            }
        })
    }

    /**
     * 事件状态渲染
     * @param {*} data 
     * @returns 
     */
    renderEventStatus(data) {
        if (data.status == GLOBAL.LIVE_EVENT.STATUS.PLAYING) {
            return (
                <div className='event-status living'>
                    <span className='event-status_icon'>
                        <img src={require('../../assets/images/icon/playing.gif').default} />
                    </span>
                    <span>{this.props.t('liveEvent.live')}</span>
                </div>
            )
        } else if (data.status == GLOBAL.LIVE_EVENT.STATUS.UPCOMING) {
            return (
                <div className='event-status'>
                    {this.props.t('liveEvent.upcomming')}
                </div>
            )
        } else {
            return (
                <div className='event-status'>
                    {this.props.t('liveEvent.ended')}
                </div>
            )
        }
    }

    /**
     * 事件监听
     */
    handleListenerEvent() {
        let events = document.querySelectorAll(".event-item");
        events.forEach((el, index) => {
            el.addEventListener("onFocus", () => {
                this.scrollHandler = scrollTextOnFocus(findChildNodeDeep(el, '.event-item_name'), {
                    duration: 2, // 自定义滚动持续时间
                    autoScroll: true // 聚焦时自动滚动
                });
                this.scrollHandler.startScroll();
            });
            el.addEventListener("onBlur", () => {
                if (this.scrollHandler) {
                    this.scrollHandler.resetScroll();
                }
            });
            el.addEventListener("left", this.handleEventLeft.bind(this, el));
            el.addEventListener("right", this.handleEventRight.bind(this, el));
            el.addEventListener("up", this.handleEventUp.bind(this, el));
            el.addEventListener("down", this.handleEventDown.bind(this, el));
            el.addEventListener("mouseenter", this.handleEventMouseEnter.bind(this, el));
            el.addEventListener("click", this.handleEventClick.bind(this, el));
        })
    }

    /**
     * 移除事件监听
     */
    handleRemoveListenerEvent() {
        let events = document.querySelectorAll(".event-item");
        events.forEach((el, index) => {
            el.removeEventListener("left", () => { });
            el.removeEventListener("right", () => { });
            el.removeEventListener("up", () => { });
            el.removeEventListener("down", () => { });
            el.removeEventListener("mouseenter", () => { });
            el.removeEventListener("click", () => { });
        })
    }

    /**
     * 事件左键
     * @param {*} el 
     * @returns 
     */
    handleEventLeft(el) {
        if (!el) {
            return
        }
        let parentIndex = Number(el.getAttribute("parentindex"));
        let index = Number(el.getAttribute("index"));
        let scrollEl = document.getElementById(`eventWrapper${parentIndex}`);
        let elWidth = getElementWidthWithMargin(el);
        if (Math.abs(scrollEl.offsetLeft) >= elWidth) {
            scrollEl.style.left = (parseInt(scrollEl.offsetLeft) + parseInt(elWidth)) + 'px';
        } else {
            scrollEl.style.left = "0px"
        }
        if (index == 0) {
            $tv.requestFocus(el);
            el.classList.add("levelShake");
            setTimeout(() => {
                el.classList.remove("levelShake");
            }, 1000)
        }
    }
    /**
     * 事件右键
     * @param {*} el 
     * @returns 
     */
    handleEventRight(el) {
        if (!el) {
            return
        }
        let parentIndex = Number(el.getAttribute("parentindex"));
        let index = Number(el.getAttribute("index"));
        let scrollEl = document.getElementById(`eventWrapper${parentIndex}`);
        let elWidth = getElementWidthWithMargin(el);
        if (index < this.state.liveEventList[parentIndex].events.length - 1 && index >= 2) {
            scrollEl.style.left = -((index - 2) * elWidth) + 'px'
        } else {
            if (index == this.state.liveEventList[parentIndex].events.length - 1) {
                $tv.requestFocus(el);
                el.classList.add("levelShake");
                setTimeout(() => {
                    el.classList.remove("levelShake");
                }, 1000)
            }
        }

    }
    /**
     * 事件上键
     * @param {*} el 
     */
    handleEventUp(el) {
        let parentIndex = Number(el.getAttribute("parentindex"));
        let parentElHeight = getElementHeightWithMargin(document.getElementById(`liveEventsBox${parentIndex}`));
        let scrollEl = this.liveEventWrapperRef.current;
        if (Math.abs(scrollEl.offsetTop) >= parentElHeight) {
            scrollEl.style.top = (parseInt(scrollEl.offsetTop) + parseInt(parentElHeight)) + 'px';
        } else {
            scrollEl.style.top = "0px";
            window.scrollTo(0, 0);
        }
    }
    /**
     * 事件下键
     * @param {*} el 
     */
    handleEventDown(el) {
        let parentIndex = Number(el.getAttribute("parentindex"));
        if (parentIndex == this.state.liveEventList.length - 1) {
            $tv.requestFocus(el);
            el.classList.add("verticalShake");
            setTimeout(() => {
                el.classList.remove("verticalShake");
            }, 1000)
        } else {
            if (document.getElementById(`liveEventsBox${parentIndex}`)) {
                let parentElHeight = getElementHeightWithMargin(document.getElementById(`liveEventsBox${parentIndex}`));
                let pageHeaderHeight = getElementHeightWithMargin(document.getElementsByClassName("page-title")[0]);
                let scrollEl = this.liveEventWrapperRef.current;
                if (parentIndex >= 1) {
                    scrollEl.style.top = -(((parentIndex - 1) * parentElHeight) + (pageHeaderHeight + 200)) + 'px'
                }
            }

        }
    }
    handleEventMouseEnter(el) { }
    /**
     * 事件点击
     * @param {*} el 
     */
    handleEventClick(el) {
        let parentIndex = Number(el.getAttribute("parentindex"));
        let index = Number(el.getAttribute("index"));
        let data = this.state.liveEventList[parentIndex].events[index];
        let scrollYEl = this.liveEventWrapperRef.current;
        let scrollXEl = document.getElementById(`eventWrapper${parentIndex}`);
        if (data) {
            setStorage(GLOBAL.STORAGE_KEYS.CATEGORY_LIVE_EVENT_FOCUS_INFO, {
                scrollYEl: 'liveEventList',
                scorllXEl: `eventWrapper${parentIndex}`,
                y: scrollYEl ? scrollYEl.offsetTop || 0 : 0,
                x: scrollXEl ? scrollXEl.offsetLeft || 0 : 0,
                focusId: data.assetId
            })
            this.props.history.push(`/liveEventDetail/${data.assetId}`);
        }
    }

  /**
   * LG兼容鼠标滚动获焦
   * @param {*} e 
   */
    pageWheel(e) {
        clearTimeout(this.pageWheelTimer);
        this.pageWheelTimer = setTimeout(() => {
            if (e.deltaY > 0) {
                $tv.findFocusType = 0;
                $tv.initDis = 10;
                $tv.setFocus('down');
            } else {
                $tv.findFocusType = 0;
                $tv.initDis = 80;
                $tv.setFocus('up');
            }
        }, 300);
    }

    render() {
        const { categoryName, liveEventList } = this.state;
        return (
            <div className='all-event_page' onWheel={e => this.pageWheel(e)}>
                <div className='page-title'>{categoryName}</div>
                <div className='all-event_main'>
                    <div className='live-event_list' id='liveEventList' ref={this.liveEventWrapperRef}>
                        {
                            liveEventList.map((item, index) => (
                                <div className='live-event_child--list' id={`liveEventsBox${index}`} key={index}>
                                    <div className='live-event_status--title'>{isSameDay(new Date(), new Date(item.startTime)) ? this.props.t("liveEvent.today") : (monthsShort[new Date(item.startTime).getMonth()] + ' ' + new Date(item.startTime).getDate())}</div>
                                    <div className='live-event-child--wrapper' id={'eventWrapper' + index}>
                                        {
                                            item.events.map((child, childIndex) => (
                                                <div className='event-item' focusable key={index + 'concat' + childIndex} parentindex={index} index={childIndex} id={child.assetId}>
                                                    <div className='event-item_poster'>
                                                        {
                                                            child.poster ? (<img src={child.poster} />) : null
                                                        }
                                                        {
                                                            this.renderEventStatus(child)
                                                        }
                                                    </div>
                                                    <div className='event-item_name--wrapper'>
                                                        <div className='event-item_name'>{child.name}</div>
                                                    </div>
                                                    <div className='event-item_info'>
                                                        <div className='event-time'>{formatDate(child.startTime, "hh:mm")}</div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
        )
    }
})