import React, { Component } from 'react';
import Column from '../../../../components/column/Column';
import './style/BasicRecommed.scss';

export default class BasicRecommed extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }
    /**
     * 推荐位下移
     * @param {*} propData 
     * @param {*} childData 
     */
    scorllUp(propData, childData) {
        const { data } = this.props;
        if (this.props.scorllUp) {
            this.props.scorllUp(data, childData);
        }
    }
    /**
     * 推荐位内容下移
     * @param {*} propData 
     * @param {*} childData 
     */
    scorllDown(propData, childData) {
        // console.log(propData)
        const { data } = this.props;
        if (this.props.scorllDown) {
            this.props.scorllDown(data, childData);
        }
    }
    /**
     * 推荐位内容点击
     * @param {*} propData 
     * @param {*} childData 
     */
    clickItem(propData, childData) {
        const { data } = this.props;
        if (this.props.click) {
            this.props.click(data, {...childData, x: propData.x, scorllEl: propData.scorllEl});
        }
    }

    render() {
        const { data } = this.props;
        return (
            <div className='basic-recommed'>
                <div className='recommed-title' style={{display: data.isDisplayTitle == "N" ? "none" : "block"}}>{data.title}</div>
                <Column data={data.rContent} click={(propData, childData) => {
                    this.clickItem(propData, childData)
                }} scorllUp={(propData, childData) => { this.scorllUp(propData, childData) }} scorllDown={(propData, childData) => { this.scorllDown(propData, childData) }} key={data.id} id={data.id}  showTitle={data.isDisplayContentTitle} itemStyle={data.style} />
            </div>
        )
    }
}