const GLOBAL = {
    CONFIG: {
        TENANT_CODE: "",
        USER_BEHAVIOR_LIMIT_REPORT_NUMBER: 100, // 用户行为上报条数限制
        SHOW_PURCHASE: false, // 是否显示订阅按钮
        DEFAULT_PIN: "0000", // 默认PIN码
        AD_REPORT_LIMIT_SIZE: 100 // 广告采集上报限制大小
    },
    COLLECTION_EVENT: {
        HOMEPAGE_BANNER_AD: "homepage_banner_ad", // 个人主页vast广告
        LIVE_EPG_CATCHUP_EVENT: "live_epg_catchup_event", // 直播回看事件名
        LIVE_EPG_EVENT: "live_epg_event", // 直播EPG事件名
        LIVE_EPG_TIME_SHIFT_EVENT: 'live_epg_time_shift_event', // 直播时移事件名
        PLAYER_CYCLE_TIME: "player_cycle_time", // 定时上报事件名
        PLAYER_PLAY_RECORD: 'player_play_record', // 视频播放记录事件名
        PLAYER_PLAY_EVENT: 'player_play_event', // 播放中状态事件名
        PLAYER_BUFFER_EVENT: 'player_buffer_event', // 视频缓冲情况事件名
        PLAYER_QUALITY_EVENT: 'player_quality_event', // 分辨率操作事件名
        PLAYER_ERROR_EVENT: 'player_error_event', // 视频播放错误事件名
        PLAYER_PLAY_DURATION: 'play_duration',
        PLAY_RECORD: 'play_record',
        USER_BEHAVIOR: "base_info", // 用户行为事件名
        MENU_CLICK: "menu_click", // 菜单点击事件名
        RECOMMEND_CONTENT_CLICK: "recommend_content_click", // 推荐位内容点击事件名
        PLAY_CLICK: "play_click", // 播放点击事件名
        AUTO_PALY: "auto_play", // 自动播放事件名
        RECOMMEND_CLICK: "recommend_click", // 相关影片推荐事件名
        SEARCH_CONTEXT_CLICK: "search_context_click", // 搜索内容点击事件事件名
        MESSAGE_CLICK: "message_click", // 消息点击事件事件名
        PAGE_EXIT_CLICK: "page_exit_click", // 返回点击事件名
        PAGE_VIEW_CLICK: "page_view_click", // 产品页面访问事件名
        PRODUCT_PAGE_CLICK: "product_page_click", // 产品点击购买事件名
        USER_FAVORITES_PAGE: "user_favorites_page", // 收藏页面访问事件名
        USER_HISTORY_PAGE: "user_history_page", // 历史观看页面访问事件名
        USER_MESSAGE_PAGE: "user_message_page", // 消息页面访问事件名
        DMS_COLLECTION_VOD_VIEW: "dms_collection_vod_view", //用户观看详情
        VOD_EVENT: "vod_record_event", //vod播放记录事件
        LIVE_EVENT_EVENT: "live_event_event", // 事件直播观看记录上报
        MEDIA_SHARE_EVENT: "media_share_event", // 媒资分享事件
        AD_CLICK_EVENT: "ad_click_event", // 广告点击
        AD_IMPRESS_EVENT: "ad_impress_event", // 广告展示（曝光）
        AD_PLAY_RECORD: "ad_play_record", // 视频广告播放记录上报
        AD_PODS_IMPRESS_EVENT: "ad_pods_impress_event", // 广告荚展示
        AD_PODS_CLICK_EVENT: "ad_pods_click_event", // 广告荚点击
        AD_PODS_VIEW_EVENT: "ad_pods_view_event", // 观看广告记录（浏览记录）
        AD_PODS_INTERAACTION: "ad_pods_interaction_event", // 用户与广告互动记录（含点击、悬停 超过5秒）
        AD_PODS_PLAY_RECORD: "ad_pods_play_record", // 广告播放记录（含完整播放、跳过播放）
        AD_PODS_LOAD_RECORD: "ad_pods_load_record", // 广告加载记录（加载失败，获取数-失败数=成功数）
    },

    AD_POSITON_TYPE: {
        VOD_MID: "VodMid", // 点播片中广告
        VOD_START: "VodStart", // 点播片头改革那个
        VODE_END: "VodEnd", // 点播片尾广告
        VOD_PAUSE: "VodPause", // 点播暂停广告
        LIVE_BANNER_AD: "PCBanner", // 直播PC banner广告
        LIVE_START: "LiveStart", // 直播ADMS开始广告
        LIVE_VAST: "LiveVast", // 直播Vast广告
    },
    DRM_TYPE: {
        NONE: 0, // 非DRM
        FAIRPLAY: 1, // Fairplay
        WIDEVINE: 2, // WideVine
        PLAYREADY: 3 // PlayReady
    },
    STORAGE_KEYS: { // 本地存储字段名
        IS_AUTO_SKIP_INTRO_AND_OUTRO: "isAutoSkipIntroAndOutro", // 是否自动跳过片头片尾本地存储字段
        LIVE_EVENT_EVENT: "live_event_event", // 事件直播观看记录
        PLAYER_PLAY_RECORD: "player_play_record", // 视频播放记录事件
        AD_PODS_COLLECTION_INFO: "AD_PODS_COLLECTION_INFO", // 广告信息采集信息
        LANG: "lang", // 语言
        UUID: "uuid",
        TOKEN: "token",
        SYSTEM_INFO: "sysInfo", // 系统信息
        SYSTEM_LINK_INFO: "sysLinkInfo", // 系统链接信息
        USER_INFO: "userInfo", // 用户信息
        SIGN: "sign", // 签名
        DEVICE_TYPE: "deviceType", // 设备类型
        ALLOW_SKIP: "allowSkip", // 允许跳过片头片尾
        LANG_KEY: "langKey", // 国际化语言key
        HOME_FOUCS_INFO: "homeFoucsInfo", // 首页聚焦信息
        HOME_CURRENT_MENU: "homeCurrentMenu", // 当前菜单
        LIVE_EVENT_CATEGORY: "liveEventCategory", // 事件直播分类
        CATEGORY_LIVE_EVENT_FOCUS_INFO: "categoryLiveEventFocusInfo", // 所有事件
        REPORT_CYCLE_CONFIG: "reportCycleConfig", // 上报参数配置
        ALL_RECOMMEND_FOCUS_IFNO: "allRecommendFocusInfo", // 所有推荐页面焦点记录
        CATEGORY_PAGE_FOCUS_INFO: "categoryPageFocusInfo", // 分类页面焦点记录
        CURRENT_PATH_RECORD: "currentPathRecord", // 当前页面记录
        SHOW_STARTUP_IMAGE: "showStartupImage", // 是否显示启动图
        RECORD_SHOW_DIALOG_BEFORE_FOUCS_ITEM: "recordShowDialogBeforeFoucsItem", // 记录在现实弹窗之前的聚焦节点
        RESERVATION_LIST: "reservationList", // 节目预约播放列表
        FIRST_BOOT: 'firstBoot', // 第一次启动
    },
    KEY_CODE: {
        48: 0,
        49: 1,
        50: 2,
        51: 3,
        52: 4,
        53: 5,
        54: 6,
        55: 7,
        56: 8,
        57: 9
    },
    DEVICE_TYPE: { // 设备类型
        SAMSUNG_TV: "samsungtv",
        LG_TV: "lgtv"
    },
    MENU_TYPE: { // 菜单类型
        VOD: "vod",
        LIVE_EVENT: "liveevent",
        LIVE: "LIVE",
        USER: "user",
        APPSTORE: "appstore",
        YOUTUBE: "youtube"
    },
    RECOMMEND_STYLE: { // 推荐位样式
        SCROLL_BANNER: "scrollBanner",
        STYLE_BANNER: "style_Banner",
        BIG_VERTICAL: "bigVertical",
        FULL_SCREEN_VIDEO: "full_screen_video",
        VERTICAL: "vertical",
        AD: "ad",
        HORIZONTAL: "horizontal"
    },
    ARTICLE_TYPE: { // 文章模块
        // 样式类型
        STYLE: {
            M_CYCLE_HORIZONTAL_TEXT_ARTICLE: 'M_CycleHorizontalTextArticle', // 水平滚动文字新闻类型
            M_MOST_POPULAR: 'M_MostPopular', // 热搜类型
            M_POPULAR_VIDEOS: 'M_PopularVideos', // 流行视频类型
            M_BIG_BOTH_IMAGE_TEXT_SOURCE_AD: 'M_BigBothImageTextSourceAd', // 大图广告类型
            M_NORMAL_TEXT_NEWS: 'M_NormalTextNews', // 文字类型
            M_CYCLE_HORIZONTAL_PICTURES_ARTICLE: 'M_CycleHorizontalPicturesArticle', // 图片横向滚动类型
            M_CYCLE_VERTICAL_TEXT_ARTICLE: 'M_CycleVerticalTextArticle', // 纵向滚动文字新闻
            M_SINGLE_PICTURE_ARTICLE: 'M_SinglePictureArticle', // 单张图片新闻类型
            M_MULTIPLE_PICTURES_ARTICLE: 'M_MultiplePicturesArticle', // 多张图新闻类型
            M_NORMAL_VERTICAL_VIDEO: 'M_NormalVerticalVideo', // 短视频类型
            M_NORMAL_HORIZONTAL_ARTICLE: 'M_NormalHorizontalArticle', // 水平滚动文字类型
            M_NORAML_VERTICAL_LIST_ARTICLE: 'M_NormalVerticalListArticle' // 纵向文字列表类型
        }
    },
    SHARE_TYPE: { // 分享类型
        BO: 'bo', // 自研分享
        OTHER: 'other', // 其他第三方分享
    },
    MEDIA_TYPE: { // 媒体类型
        LIVE: "live", // 直播类型
        VOD: "vod", // 点播类型
        SERIES: "series", // 系列类型
        SVOD: "svod", // 单集
        // SERIES: "episode", // 系列类型
        EPISODE: "episode", // 剧集类型
        ARTICLE: "article", // 文章类型
        WEMEDIA: "wemedia", // 自媒体类型
        LIVE_EVENT: "liveevent", // 事件直播类型
        LIVE_EVENT_CHANNEL: "liveeventchannel", // 事件直播鉴权类型
        CATEGORY: "category", // 分类
    },
    PLAY_TYPE: {
        VOD: 'vod', // 正片
        TRAILER: 'trailer', // 预告片
        TIDBITS: 'tidbits', // 花絮
        PREVIEW: 'preview', // 试看

    },
    DRM_CODE: { // DRM 类型
        DRM: 'drm',
        ASE128: 'ase128',
        VERIMATRIX_DRM: 'VerimatrixDRM',
        VMX: "vmx"
    },
    LOGIN_TYPE: { // 登录类型
        GOOGLE: "google",
        FACEBOOK: "facebook",
        APPLE: "apple",
        EMAIL_LINK: "email",
        PHONE: "phone",
        EMAIL: "email",
        FIREBASE: "firebase"
    },
    SHARE_PLATFORM: { // 分享类型platform
        FACEBOOK: "Facebook",
        TWITTER: "Twitter",
        CODE_SHARE: "codeShare"
    },
    LIVE_EVENT: { // 事件直播
        STATUS: { // 直接状态
            UPCOMING: 0, // 待播
            PLAYING: 1, // 直播中
            ENDED: 2 // 结束
        }
    },
    WATERMARK_POSITION_MAP: { // 水印位置映射
        TOP_LEFT: {
            top: 20,
            left: 20
        },
        TOP_RIGHT: {
            top: 20,
            right: 20
        },
        BOTTOM_LEFT: {
            bottom: 50,
            left: 20
        },
        BOTTOM_RIGHT: {
            bottom: 20,
            right: 20
        }
    },
    VIDEO_PLAY_STATUS: { // 视频播放状态
        PLAYING: "playing", // 播放中
        PAUSED: "paused", // 暂停
        ENDED: "ended", // 已结束
    },
    // response
    HTTP_RESPONSE_CODE: {
        SUCCESS: 0, // 请求成功
        DRM_TOKEN_ERROR: 4046, // 播放失败：未能获取DRM令牌
        FIREBASE_LOGIN_ERROR: 4045, // 登录失败：无法调用Firebase服务
        LIMIT_PEOPLE: 5005, // 请求人数过多，请稍后再试
        AD_SIGN_INVALID: 1001, // 广告签名失效
        USER_EXPIRATION: 1005, // 用户异常
        CONTENT_NOT_EXIST: 4049, // 内容不存在
        USER_NOT_LOGIN: 1009, // 用户未登录
        PLAY_AREA_LIMIT: 1051, // 播放区域限制
        PLAY_DEVICE_LIMIT: 1064, // 播放设备限制
        NEED_BY: 2000, // 需要购买
        VOUCHER_CODE_USED: 1055, // 兑换码已使用
        VOUCHER_CODE_NOT_EXIST: 1039, // 兑换码不存在或已使用
        VOUCHER_CODE_EXPIRED: 1040, // 兑换码过期
        VOUCHER_CODE_NOT_YET_EFFECT: 1041, // 兑换码未激活
        VOUCHER_CODE_INVALID: 1042, // 兑换码无效
        SAME_PACKAGE_ACTIVATED: 1069, // 有相同包
        NOT_EXIST: -1, // 不存在
    },
    AD_POSITION: { // 广告位置
        TOP: "top",
        BOTTOM: "bottom",
        LEFT: "left",
        RIGHT: "right",
        CENTER: "center"
    },
    /**
     * 视频类型：
    如果adType=playlistBanner，则值为playlist
    如果adType=preRoll、midRoll、endRoll，则值为vod
    如果adType=banner、trajectory，具体依据情况定，如果是点播，则为vod，如果为事件直播，则为liveevent
    */
    COLLECTION_VIDEO_TYPE: {
        PLAY_LIST: "playlist",
        VOD: "vod",
        LIVE_EVENT: "liveevent",
    },
    // 广告类型
    AD_TYPE: {
        IMAGE: "image", // 图片类型
        VIDEO: "video", // 视频广告
    },
    COLLECTION_AD_TYPE: {
        "banner": "banner", // banner广告
        "trajectory": "trajectory", // 轨迹广告
        "playlist-banner": "playlistBanner", // 推荐位Banner广告
        "pre-roll": "preRoll", // 片头广告
        "mid-roll": "midRoll", // 片中广告
        "end-roll": "endRoll", // 片尾
    },
    AD_MATERIAL_TYPE: { // 广告素材类型
        BANNER: "banner", // banner广告
        TRAJECTORY: "trajectory", // 轨迹广告
        PLAYLIST_BANNER: "playlist-banner", // 推荐位Banner广告
        PRE_ROLL: "pre-roll", // 片头广告
        MID_ROLL: "mid-roll", // 片中广告
        END_ROLL: "end-roll", // 片尾
    },
    AD_INTERACTION_TYPE: { // 互动类型：click（点击），hover（鼠标悬浮）
        CLICK: "click",
        HOVER: "hover"
    },
    AD_PLAY_STATUS: { // 广告播放状态
        NOT_PLAYED: 1, // 未播放
        PLAYING: 2, // 播放中
        PLAY_ENDED: 3  // 播放结束
    },
    AD_SHOW_ANIMATE_CSS: { // 轨迹显示动画
        left: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateY(-90deg) translateY(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        right: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateY(-90deg) translateY(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        top: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateX(-90deg) translateX(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        bottom: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateX(-90deg) translateX(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        center: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateY(-90deg) translate(-50%, -50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        }
    },
    AD_HIDE_ANIMATE_CSS: { // 轨迹隐藏动画
        left: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateY(0deg) translateY(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        right: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateY(0deg) translateY(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        top: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateX(0deg) translateX(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        bottom: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "rotateX(0deg) translateX(-50%)",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        },
        center: {
            "transition": "transform 0.3s ease-in-out",
            "transform": "",
            "transformStyle": "preserve-3d",
            "transformOrigin": "center"
        }
    },
    SHAKA_ERROR_CATEGORY: { // shaka player错误分类
        1: 'Errors from the network stack',
        2: 'Errors parsing text streams',
        3: 'Errors parsing or processing audio or video streams',
        4: 'Errors parsing the Manifest',
        5: 'Errors related to streaming',
        6: 'Errors related to DRM',
        7: 'Miscellaneous errors from the player',
        8: 'Errors related to cast',
        9: 'Errors in the database storage (offline)',
        10: 'Errors related to ad insertion'
    },
    SHAKA_ERROR_CODE: { // shaka player错误码
        "1000": "UNSUPPORTED_SCHEME",
        "1001": "BAD_HTTP_STATUS",
        "1002": "HTTP_ERROR",
        "1003": "TIMEOUT",
        "1004": "MALFORMED_DATA_URI",
        "1006": "REQUEST_FILTER_ERROR",
        "1007": "RESPONSE_FILTER_ERROR",
        "1008": "MALFORMED_TEST_URI",
        "1009": "UNEXPECTED_TEST_REQUEST",
        "1010": "ATTEMPTS_EXHAUSTED",
        "1011": "SEGMENT_MISSING",
        "2000": "INVALID_TEXT_HEADER",
        "2001": "INVALID_TEXT_CUE",
        "2003": "UNABLE_TO_DETECT_ENCODING",
        "2004": "BAD_ENCODING",
        "2005": "INVALID_XML",
        "2007": "INVALID_MP4_TTML",
        "2008": "INVALID_MP4_VTT",
        "2009": "UNABLE_TO_EXTRACT_CUE_START_TIME",
        "2010": "INVALID_MP4_CEA",
        "2011": "TEXT_COULD_NOT_GUESS_MIME_TYPE",
        "2012": "CANNOT_ADD_EXTERNAL_TEXT_TO_SRC_EQUALS",
        "2013": "TEXT_ONLY_WEBVTT_SRC_EQUALS",
        "2014": "MISSING_TEXT_PLUGIN",
        "2015": "CHAPTERS_TRACK_FAILED",
        "2017": "UNSUPPORTED_EXTERNAL_THUMBNAILS_URI",
        "3000": "BUFFER_READ_OUT_OF_BOUNDS",
        "3001": "JS_INTEGER_OVERFLOW",
        "3002": "EBML_OVERFLOW",
        "3003": "EBML_BAD_FLOATING_POINT_SIZE",
        "3004": "MP4_SIDX_WRONG_BOX_TYPE",
        "3005": "MP4_SIDX_INVALID_TIMESCALE",
        "3006": "MP4_SIDX_TYPE_NOT_SUPPORTED",
        "3007": "WEBM_CUES_ELEMENT_MISSING",
        "3008": "WEBM_EBML_HEADER_ELEMENT_MISSING",
        "3009": "WEBM_SEGMENT_ELEMENT_MISSING",
        "3010": "WEBM_INFO_ELEMENT_MISSING",
        "3011": "WEBM_DURATION_ELEMENT_MISSING",
        "3012": "WEBM_CUE_TRACK_POSITIONS_ELEMENT_MISSING",
        "3013": "WEBM_CUE_TIME_ELEMENT_MISSING",
        "3014": "MEDIA_SOURCE_OPERATION_FAILED",
        "3015": "MEDIA_SOURCE_OPERATION_THREW",
        "3016": "VIDEO_ERROR",
        "3017": "QUOTA_EXCEEDED_ERROR",
        "3018": "TRANSMUXING_FAILED",
        "3019": "CONTENT_TRANSFORMATION_FAILED",
        "3020": "MSS_MISSING_DATA_FOR_TRANSMUXING",
        "3021": "MSS_TRANSMUXING_CODEC_UNKNOWN",
        "3022": "MSS_TRANSMUXING_FAILED",
        "3023": "TRANSMUXING_NO_VIDEO_DATA",
        "4000": "UNABLE_TO_GUESS_MANIFEST_TYPE",
        "4001": "DASH_INVALID_XML",
        "4002": "DASH_NO_SEGMENT_INFO",
        "4003": "DASH_EMPTY_ADAPTATION_SET",
        "4004": "DASH_EMPTY_PERIOD",
        "4005": "DASH_WEBM_MISSING_INIT",
        "4006": "DASH_UNSUPPORTED_CONTAINER",
        "4007": "DASH_PSSH_BAD_ENCODING",
        "4008": "DASH_NO_COMMON_KEY_SYSTEM",
        "4009": "DASH_MULTIPLE_KEY_IDS_NOT_SUPPORTED",
        "4010": "DASH_CONFLICTING_KEY_IDS",
        "4012": "RESTRICTIONS_CANNOT_BE_MET",
        "4015": "HLS_PLAYLIST_HEADER_MISSING",
        "4016": "INVALID_HLS_TAG",
        "4017": "HLS_INVALID_PLAYLIST_HIERARCHY",
        "4018": "DASH_DUPLICATE_REPRESENTATION_ID",
        "4020": "HLS_MULTIPLE_MEDIA_INIT_SECTIONS_FOUND",
        "4023": "HLS_REQUIRED_ATTRIBUTE_MISSING",
        "4024": "HLS_REQUIRED_TAG_MISSING",
        "4025": "HLS_COULD_NOT_GUESS_CODECS",
        "4026": "HLS_KEYFORMATS_NOT_SUPPORTED",
        "4027": "DASH_UNSUPPORTED_XLINK_ACTUATE",
        "4028": "DASH_XLINK_DEPTH_LIMIT",
        "4032": "CONTENT_UNSUPPORTED_BY_BROWSER",
        "4033": "CANNOT_ADD_EXTERNAL_TEXT_TO_LIVE_STREAM",
        "4036": "NO_VARIANTS",
        "4037": "PERIOD_FLATTENING_FAILED",
        "4038": "INCONSISTENT_DRM_ACROSS_PERIODS",
        "4039": "HLS_VARIABLE_NOT_FOUND",
        "4040": "HLS_MSE_ENCRYPTED_MP2T_NOT_SUPPORTED",
        "4041": "HLS_MSE_ENCRYPTED_LEGACY_APPLE_MEDIA_KEYS_NOT_SUPPORTED",
        "4042": "NO_WEB_CRYPTO_API",
        "4045": "CANNOT_ADD_EXTERNAL_THUMBNAILS_TO_LIVE_STREAM",
        "4046": "MSS_INVALID_XML",
        "4047": "MSS_LIVE_CONTENT_NOT_SUPPORTED",
        "4048": "AES_128_INVALID_IV_LENGTH",
        "4049": "AES_128_INVALID_KEY_LENGTH",
        "4050": "DASH_CONFLICTING_AES_128",
        "4051": "DASH_UNSUPPORTED_AES_128",
        "5006": "STREAMING_ENGINE_STARTUP_INVALID_STATE",
        "6000": "NO_RECOGNIZED_KEY_SYSTEMS",
        "6001": "REQUESTED_KEY_SYSTEM_CONFIG_UNAVAILABLE",
        "6002": "FAILED_TO_CREATE_CDM",
        "6003": "FAILED_TO_ATTACH_TO_VIDEO",
        "6004": "INVALID_SERVER_CERTIFICATE",
        "6005": "FAILED_TO_CREATE_SESSION",
        "6006": "FAILED_TO_GENERATE_LICENSE_REQUEST",
        "6007": "LICENSE_REQUEST_FAILED",
        "6008": "LICENSE_RESPONSE_REJECTED",
        "6010": "ENCRYPTED_CONTENT_WITHOUT_DRM_INFO",
        "6012": "NO_LICENSE_SERVER_GIVEN",
        "6013": "OFFLINE_SESSION_REMOVED",
        "6014": "EXPIRED",
        "6015": "SERVER_CERTIFICATE_REQUIRED",
        "6016": "INIT_DATA_TRANSFORM_ERROR",
        "6017": "SERVER_CERTIFICATE_REQUEST_FAILED",
        "6018": "MIN_HDCP_VERSION_NOT_MATCH",
        "6019": "ERROR_CHECKING_HDCP_VERSION",
        "7000": "LOAD_INTERRUPTED",
        "7001": "OPERATION_ABORTED",
        "7002": "NO_VIDEO_ELEMENT",
        "7003": "OBJECT_DESTROYED",
        "7004": "CONTENT_NOT_LOADED",
        "8000": "CAST_API_UNAVAILABLE",
        "8001": "NO_CAST_RECEIVERS",
        "8002": "ALREADY_CASTING",
        "8003": "UNEXPECTED_CAST_ERROR",
        "8004": "CAST_CANCELED_BY_USER",
        "8005": "CAST_CONNECTION_TIMED_OUT",
        "8006": "CAST_RECEIVER_APP_UNAVAILABLE",
        "9000": "STORAGE_NOT_SUPPORTED",
        "9001": "INDEXED_DB_ERROR",
        "9002": "DEPRECATED_OPERATION_ABORTED",
        "9003": "REQUESTED_ITEM_NOT_FOUND",
        "9004": "MALFORMED_OFFLINE_URI",
        "9005": "CANNOT_STORE_LIVE_OFFLINE",
        "9007": "NO_INIT_DATA_FOR_OFFLINE",
        "9008": "LOCAL_PLAYER_INSTANCE_REQUIRED",
        "9011": "NEW_KEY_OPERATION_NOT_SUPPORTED",
        "9012": "KEY_NOT_FOUND",
        "9013": "MISSING_STORAGE_CELL",
        "9014": "STORAGE_LIMIT_REACHED",
        "9015": "DOWNLOAD_SIZE_CALLBACK_ERROR",
        "9016": "MODIFY_OPERATION_NOT_SUPPORTED",
        "9017": "INDEXED_DB_INIT_TIMED_OUT",
        "10000": "CS_IMA_SDK_MISSING",
        "10001": "CS_AD_MANAGER_NOT_INITIALIZED",
        "10002": "SS_IMA_SDK_MISSING",
        "10003": "SS_AD_MANAGER_NOT_INITIALIZED",
        "10004": "CURRENT_DAI_REQUEST_NOT_FINISHED",
        "10005": "MT_AD_MANAGER_NOT_INITIALIZED"
    }
}

export default GLOBAL