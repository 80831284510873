import React, { Component } from 'react';
import { $tv } from 'react-tv-focusable';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getElementWidthWithMargin, scrollTextOnFocus, findChildNodeDeep, getStorage, getUUID, formatDecimals, setStorage } from '../../../../util/util'
import GLOBAL from "../../../../util/global"
import $request from '../../../../api';
import projectConfig from '../../../../util/config';
import './style/Me.scss';

class Me extends Component {
    constructor(props) {
        super(props);
        this.loginButtonRef = React.createRef(); // 登录按钮节点
        this.menuWrapperRef = React.createRef(); // 我的菜单盒子节点
        this.productsRef = React.createRef(); // 产品包列表盒子节点
        this.productPackageItemsRef = []; // 产品包item项节点列表
        this.menuRef = []; // 我的菜单
        this.isMountedFlag = false; // 添加一个标志
        this.state = {
            isLogin: false, // 是否登录
            userInfo: null,
            productList: [], // 产品包列表
            meunList: [
                {
                    type: 'subscription',
                    name: this.props.t("me.subscription"),
                    background: require('../../../../assets/images/icon/home_me_subscription_bg.png').default
                },
                {
                    type: 'transactionHistory',
                    name: this.props.t("me.transactionHistory"),
                    background: require('../../../../assets/images/icon/home_me_purchase_history_bg.png').default
                },
                {
                    type: 'myFavorites',
                    name: this.props.t("me.myFavorites"),
                    background: require('../../../../assets/images/icon/home_me_favorites_bg.png').default
                },
                {
                    type: 'watchHistory',
                    name: this.props.t("me.watchHistory"),
                    background: require('../../../../assets/images/icon/home_me_play_list_bg.png').default
                },
                {
                    type: 'claimTheVoucherCode',
                    name: this.props.t("me.claimTheVoucherCode"),
                    background: require('../../../../assets/images/icon/home_me_redemption_code_bg.png').default
                },
                {
                    type: 'messages',
                    name: this.props.t("me.messages"),
                    background: require('../../../../assets/images/icon/home_me_message_bg.png').default
                },
                {
                    type: 'signOut',
                    name: this.props.t("me.signOut"),
                    background: require('../../../../assets/images/icon/home_me_sign_out_bg.png').default
                }
            ]
        }
    }

    componentDidMount() {
        // console.log('888888888')
        this.isMountedFlag = true; // 组件挂载时设置标志为 true
        this.getProductBaseList();
        if (getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)) {
            this.setState({
                isLogin: true,
                userInfo: getStorage(GLOBAL.STORAGE_KEYS.USER_INFO)
            }, () => {

            })
            this.hanldeListenerMeMenu();

        } else {
            this.handleListenerLoginBtn();
        }
        // 还原记住的位置
        if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
            let foucsInfo = getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO);
            if (foucsInfo.y) {
                window.scrollTo(foucsInfo.y, 0);
            }
            if (foucsInfo.scorllEl) {
                if (document.getElementById(foucsInfo.scorllEl)) {
                    document.getElementById(foucsInfo.scorllEl).style.left = foucsInfo.x + 'px' || 0;
                }
            }
            if (foucsInfo.focusId) {
                if (document.getElementById(foucsInfo.focusId)) {
                    $tv.requestFocus(document.getElementById(foucsInfo.focusId));
                }
            }
        }
        document.getElementById("root").style.backgroundImage = `none`;
        document.getElementsByClassName("home")[0].style.background = "#000";
    }

    componentWillUnmount() {
        this.isMountedFlag = false; // 组件卸载时设置标志为 false
        this.removeListenerMeMenu();
        this.handleRemoveListenerPackage();
    }

    /**
     * 登录按钮监听
     */
    handleListenerLoginBtn() {
        this.loginButtonRef.current.addEventListener("click", () => {
            if (this.props.loginClick) {
                this.props.loginClick()
            }
        })
        this.loginButtonRef.current.addEventListener("left", () => {
            $tv.requestFocus(this.loginButtonRef.current);
        })
        this.loginButtonRef.current.addEventListener("right", () => {
            $tv.requestFocus(this.loginButtonRef.current);
        })
        this.loginButtonRef.current.addEventListener("up", () => {
            if (this.props.up) {
                this.props.up(this.loginButtonRef.current)
            }
        })
        this.loginButtonRef.current.addEventListener("down", () => {
            $tv.requestFocus(this.loginButtonRef.current);
        })
    }

    /**
     * 我的菜单监听
     */
    hanldeListenerMeMenu() {
        // console.log()
        if (this.menuRef && this.menuRef.length) {
            let scorllEl = this.menuWrapperRef.current;
            this.menuRef.forEach((el, index) => {
                el.addEventListener("click", this.handleMenuClick.bind(this, index));
                el.addEventListener("up", () => {
                    if (this.props.up) {
                        this.props.up(el)
                    }
                });
                el.addEventListener("down", () => {
                    if (this.props.down) {
                        this.props.down(el)
                    }
                });
                el.addEventListener("left", () => {
                    let elWidth = getElementWidthWithMargin(el);
                    if (Math.abs(scorllEl.offsetLeft) >= elWidth) {
                        scorllEl.style.left = (parseInt(scorllEl.offsetLeft) + parseInt(elWidth)) + 'px';
                    } else {
                        scorllEl.style.left = "0px"
                    }
                    if (index == 0 && el) {
                        $tv.requestFocus(el);
                        el.classList.add("levelShake");
                        setTimeout(() => {
                            if (el) {
                                el.classList.remove("levelShake");
                            }
                        }, 1000)
                    }
                })
                el.addEventListener("right", () => {
                    let elWidth = getElementWidthWithMargin(el);
                    if (index >= 2 && index < this.state.meunList.length - 1) {
                        scorllEl.style.left = -((index - 2) * elWidth) + 'px';
                    } else {
                        if (index == this.state.meunList.length - 1 && el) {
                            $tv.requestFocus(el);
                            el.classList.add("levelShake");
                            setTimeout(() => {
                                if (el) {
                                    el.classList.remove("levelShake");
                                }
                            }, 1000)
                        }
                    }
                })
            })
        }
    }

    removeListenerMeMenu() {
        if (this.menuRef && this.menuRef.length) {
            this.menuRef.forEach(el => {
                el.removeEventListener("click", () => { });
                el.removeEventListener("left", () => { });
                el.removeEventListener("right", () => { });
                el.removeEventListener("up", () => { });
                el.removeEventListener("down", () => { });
            })
        }
    }

    handleMenuClick(index) {
        if (this.props.click) {
            let scorllEl = this.menuWrapperRef.current;
            this.props.click({ ...this.state.meunList[index], x: scorllEl.offsetLeft, scorllEl: 'meMenuScrolll' });
        }

    }

    /**
     * 获取基础包
     */
    getProductBaseList() {
        $request.boApi.product.getProductBaseList({
            page: 0,
            pageSize: 60,
            mac: getUUID()
        }).then(res => {
            if (res.data.resultCode == GLOBAL.HTTP_RESPONSE_CODE.SUCCESS) {
                if (res.data.products) {
                    if (this.isMountedFlag) {
                        this.setState({
                            productList: res.data.products || []
                        }, () => {
                            this.handleListenerPackage();
                            // 还原记住的位置
                            if (getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO)) {
                                let foucsInfo = getStorage(GLOBAL.STORAGE_KEYS.HOME_FOUCS_INFO);
                                if (foucsInfo.y) {
                                    window.scrollTo(foucsInfo.y, 0);
                                }
                                if (foucsInfo.scorllEl) {
                                    if (document.getElementById(foucsInfo.scorllEl)) {
                                        document.getElementById(foucsInfo.scorllEl).style.left = foucsInfo.x + 'px' || 0;
                                    }
                                }
                                if (foucsInfo.focusId) {
                                    if (document.getElementById(foucsInfo.focusId)) {
                                        $tv.requestFocus(document.getElementById(foucsInfo.focusId));
                                    }
                                }
                            }
                        });
                    }

                }
            }
        })
    }

    handleListenerPackage() {
        if (this.productPackageItemsRef && this.productPackageItemsRef.length) {
            let scorllEl = this.productsRef.current;
            this.productPackageItemsRef.forEach((el, index) => {
                el.addEventListener("click", () => {
                    if (this.props.click) {
                        this.props.click({ ...this.state.productList[index], x: scorllEl.offsetLeft, scorllEl: 'packageScrollWrapper', type: `packageItem${this.state.productList[index].productId}` });
                    }
                    this.props.history.push(`/packageDetail/${this.state.productList[index].productId}`);
                })
                el.addEventListener("left", () => {
                    let elWidth = getElementWidthWithMargin(el);
                    if (Math.abs(scorllEl.offsetLeft) >= elWidth) {
                        scorllEl.style.left = (parseInt(scorllEl.offsetLeft) + parseInt(elWidth)) + 'px';
                    } else {
                        scorllEl.style.left = "0px"
                    }
                    if (index == 0 && el) {
                        $tv.requestFocus(el);
                        el.classList.add("levelShake");
                        setTimeout(() => {
                            if (el) {
                                el.classList.remove("levelShake");
                            }
                        }, 1000)
                    }
                })
                el.addEventListener("right", () => {
                    let elWidth = getElementWidthWithMargin(el);
                    console.log(index)
                    if (index >= 2 && index < this.state.productList.length - 1) {
                        scorllEl.style.left = -((index - 2) * elWidth) + 'px';
                    } else {
                        if (index == this.state.productList.length - 1 && el) {
                            $tv.requestFocus(el);
                            el.classList.add("levelShake");
                            setTimeout(() => {
                                if (el) {
                                    el.classList.remove("levelShake");
                                }
                            }, 1000)
                        }
                    }
                })
                el.addEventListener("onFocus", () => {
                    let titleEl = findChildNodeDeep(el, '.name');
                    if (titleEl) {
                        this.scrollHandler = scrollTextOnFocus(titleEl, {
                            duration: 2, // 自定义滚动持续时间
                            autoScroll: true // 聚焦时自动滚动
                        });
                        this.scrollHandler.startScroll();
                    }
                });
                el.addEventListener("onBlur", () => {
                    this.scrollHandler.resetScroll();
                });
                el.addEventListener("down", () => {
                    $tv.requestFocus(el);
                    el.classList.add("verticalShake");
                    setTimeout(() => {
                        if (el) {
                            el.classList.remove("verticalShake");
                        }
                    }, 1000)
                })
            })
        }
    }

    handleRemoveListenerPackage() {
        this.productPackageItemsRef.forEach(el => {
            el.removeEventListener("click", () => { })
            el.removeEventListener("left", () => { })
            el.removeEventListener("right", () => { })
            el.removeEventListener("down", () => { })
        })
    }

    render() {
        const { isLogin, userInfo, meunList, productList } = this.state;
        return (
            <div className='me-wrapper'>
                <div className='not-login_wrapper' style={{ display: !isLogin ? 'flex' : 'none' }}>
                    <div className='tips'>{this.props.t("me.loginTips1")}</div>
                    <div className='tips'>{this.props.t("me.loginTips2")}</div>
                    <div className='login-btn' id='homeLoginBtn' ref={this.loginButtonRef} focusable>{this.props.t("me.signIn")}</div>
                </div>
                <div className='login-wrapper' style={{ display: isLogin ? 'block' : 'none' }}>
                    <div className='user-info_wrapper'>
                        <div className='user-avater'>
                            <img src={require('../../../../assets/images/icon/atv_me_icon_userhead.png').default} />
                        </div>
                        <div className='user-nickname_email'>
                            <div className='nickname'>{userInfo ? (userInfo.nickName || userInfo.name) : ""}</div>
                            <div className='email'>
                                <div className='icon'>
                                    <img src={require('../../../../assets/images/icon/atv_me_icon_email.png').default} />
                                </div>
                                <div>{this.props.t("user.Email")} {userInfo ? userInfo.email : ''}</div>
                            </div>
                        </div>
                    </div>
                    <div className='me-menu_wrapper'>
                        <div className='me-menu_list' id='meMenuScrolll' ref={this.menuWrapperRef}>
                            {
                                meunList ? meunList.map((item, index) => (
                                    <div className='menu-item' id={item.type} key={item.type} ref={(el) => { this.menuRef[index] = el }} focusable style={{ backgroundImage: `url(${item.background})` }}>{item.name}</div>
                                )) : null
                            }
                        </div>
                    </div>

                    <div className='purchase-package'>
                        <div className='purchase-package_title' style={{display: productList && productList.length ? 'block' : 'none'}}>
                            {this.props.t("me.purchasePackage")}
                        </div>
                        <div className='package-list'>
                            <div className='package-wrapper' id='packageScrollWrapper' ref={this.productsRef}>
                                {
                                    productList.map((item, index) => (
                                        <div className='list-item' key={index} >
                                            <div className='horizontal-style package-item' id={`packageItem${item.productId}`} focusable ref={(el) => { this.productPackageItemsRef[index] = el }}>
                                                {(item.poster || item.vPoster) ? (<img src={item.poster || item.vPoster} />) : null}
                                                <div className='package-info'>
                                                    <div className='package-info_wrapper'>
                                                        <div className='name'>{item.productName}</div>
                                                        <div className='price'>{item.currency} {formatDecimals(item.price || 0)} /{item.cycle} {item.cycleUnit}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Me.propTypes = {

}

export default withTranslation()(withRouter(Me));